.gradient-border-yellow {
  position: relative;
  border-radius: 26.25px;
  /* border: 1px solid white; */
  overflow: hidden;
  background: #fcca61;
  backdrop-filter: blur(8px) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  /* padding: 20px !important; */
  /* z-index: 1; */
}

.gradient-border-yellow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    114.53deg,
    /* white 0%, */ /* white 10%, */ rgba(255, 255, 255, 0) 30%,
    /* Invisible middle section */ rgba(255, 255, 255, 0) 70%,
    /* Continue invisible */ #fcca61 90%,
    white 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* z-index: 0;  */
  backdrop-filter: blur(20px) !important;
}

@media only screen and (max-width: 600px){
  .lock-scroll {
    overflow: hidden;
    height: 100vh; 
  }
}
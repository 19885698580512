.box {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  transform: translate(-50%, 0);
  bottom: 0;
  cursor: pointer;
  outline: none;
  width: 2rem;
}
.box img,
.lid {
  width: 2rem !important;
}

.box-lid {
  width: 4rem;
}

.kuku {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 100%;
  z-index: -1;
}

.lid {
  position: absolute;
  top: 0;
  left: 50%;
  top: -50%;
  transform: translateX(-50%, -50%);
}

.move {
  animation: 1s wiggle infinite linear;
}

.rotating {
  animation: 1s rotating;
}

.rotated {
  transform: rotate(145deg) translate(-70%, -170px);
}

@keyframes wiggle {
  10%,
  90% {
    transform: translate(-50%) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate(-50%) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate(-50%) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate(-50%) translate3d(4px, 0, 0);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg) translate(-50%, 0);
  }

  100% {
    transform: rotate(145deg) translate(-70%, -170px);
  }
}

@keyframes jump {
  from {
    bottom: 0;
  }
  to {
    bottom: 20em;
  }
}

.votingSectionLeft {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #fff 49%,
    rgba(255, 255, 255, 0) 95.5%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  will-change: background-position;
  animation: gradient 6s infinite forwards;
  background-size: 100% 400%;
}

@keyframes gradient {
  0%,
  100% {
    background-position: center top;
  }
  50% {
    background-position: center bottom;
  }
}
.voting-Text {
  text-shadow: 3.372px 6.743px 3.372px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: #000;
}

@media screen and (max-width: 1024px) {
  .voting-Text {
    text-shadow: 3.372px 6.743px 3.372px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000;
  }
}

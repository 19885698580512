@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Hey August";
  src: url("./assets/fonts/hey-august/Hey-August.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DK Scrawny";
  src: url("./assets/fonts/dk_scrawny_cat/DK-Scrawny-Cat.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  background-color: #f1ffe6;
  overflow-x: hidden;
  width: 100vw;
}
:root {
  --onboard-modal-z-index: 1000;
  --account-center-z-index: 1000;
}
.inner-row {
  display: none !important;
}
.svelte-1o9vinu{
  display: none !important;
}



.confetti {
    /* width: 440px; */
    margin: 500px auto 0;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    position: absolute;
    bottom: 4em;
    left: 50%;
    transform: translate(-50%);
}
.confetti p {
    position: absolute;
    display: block;
    left: 50%;
    bottom: 0;
    width: 2px;
    height: 3px;
    opacity: 0.8;
}
.confetti.animated p:nth-of-type(1) {
    transform: skewY(13deg) rotate(285deg) translate(68px, -515px) scale(0.4);
    background: rgba(0, 255, 162, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(2) {
    transform: skewY(24deg) rotate(322deg) translate(33px, -2470px) scale(0.5);
    background: rgba(0, 255, 123, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(3) {
    transform: skewY(44deg) rotate(339deg) translate(5px, -810px) scale(0.5);
    background: rgba(0, 102, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(4) {
    transform: skewY(30deg) rotate(196deg) translate(20px, -830px) scale(0.2);
    background: rgba(255, 0, 179, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(5) {
    transform: skewY(46deg) rotate(239deg) translate(33px, -1480px) scale(0.4);
    background: rgba(0, 64, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(6) {
    transform: skewY(29deg) rotate(34deg) translate(11px, -720px) scale(0.3);
    background: rgba(255, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(7) {
    transform: skewY(32deg) rotate(251deg) translate(34px, -2415px) scale(0.4);
    background: rgba(255, 0, 51, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(8) {
    transform: skewY(33deg) rotate(198deg) translate(71px, -1895px) scale(0.3);
    background: rgba(255, 17, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(9) {
    transform: skewY(27deg) rotate(254deg) translate(19px, -1780px) scale(0.1);
    background: rgba(0, 255, 85, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(10) {
    transform: skewY(46deg) rotate(114deg) translate(79px, -1985px) scale(0.2);
    background: rgba(255, 225, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(11) {
    transform: skewY(8deg) rotate(254deg) translate(69px, -50px) scale(0.4);
    background: rgba(255, 153, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(12) {
    transform: skewY(4deg) rotate(16deg) translate(78px, -1225px) scale(0.2);
    background: rgba(255, 0, 76, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(13) {
    transform: skewY(44deg) rotate(330deg) translate(72px, -1810px) scale(0.1);
    background: rgba(0, 204, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(14) {
    transform: skewY(35deg) rotate(112deg) translate(70px, -465px) scale(0.2);
    background: rgba(255, 0, 85, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(15) {
    transform: skewY(5deg) rotate(17deg) translate(39px, -940px) scale(0.5);
    background: rgba(255, 0, 76, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(16) {
    transform: skewY(39deg) rotate(35deg) translate(93px, -1950px) scale(0.2);
    background: rgba(179, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(17) {
    transform: skewY(21deg) rotate(207deg) translate(8px, -1335px) scale(0.3);
    background: rgba(255, 166, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(18) {
    transform: skewY(16deg) rotate(40deg) translate(73px, -1850px) scale(0.3);
    background: rgba(0, 238, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(19) {
    transform: skewY(8deg) rotate(122deg) translate(98px, -1085px) scale(0.3);
    background: rgba(0, 255, 230, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(20) {
    transform: skewY(42deg) rotate(171deg) translate(31px, -775px) scale(0.4);
    background: rgba(0, 30, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(21) {
    transform: skewY(36deg) rotate(15deg) translate(50px, -1675px) scale(0.4);
    background: rgba(85, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(22) {
    transform: skewY(35deg) rotate(261deg) translate(60px, -45px) scale(0.4);
    background: rgba(255, 0, 183, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(23) {
    transform: skewY(19deg) rotate(257deg) translate(48px, -480px) scale(0.5);
    background: rgba(255, 0, 136, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(24) {
    transform: skewY(2deg) rotate(32deg) translate(54px, -2130px) scale(0.2);
    background: rgba(0, 255, 47, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(25) {
    transform: skewY(18deg) rotate(280deg) translate(96px, -1550px) scale(0.5);
    background: rgba(255, 0, 230, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(26) {
    transform: skewY(17deg) rotate(143deg) translate(25px, -2490px) scale(0.3);
    background: rgba(0, 255, 230, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(27) {
    transform: skewY(4deg) rotate(316deg) translate(22px, -1005px) scale(0.1);
    background: rgba(234, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(28) {
    transform: skewY(30deg) rotate(151deg) translate(53px, -1460px) scale(0.4);
    background: rgba(191, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(29) {
    transform: skewY(19deg) rotate(42deg) translate(81px, -1760px) scale(0.5);
    background: rgba(0, 157, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(30) {
    transform: skewY(31deg) rotate(339deg) translate(95px, -1480px) scale(0.1);
    background: rgba(251, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(31) {
    transform: skewY(41deg) rotate(281deg) translate(41px, -965px) scale(0.2);
    background: rgba(255, 64, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(32) {
    transform: skewY(13deg) rotate(22deg) translate(29px, -570px) scale(0.4);
    background: rgba(85, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(33) {
    transform: skewY(16deg) rotate(62deg) translate(70px, -2365px) scale(0.4);
    background: rgba(106, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(34) {
    transform: skewY(5deg) rotate(335deg) translate(2px, -635px) scale(0.4);
    background: rgba(255, 0, 128, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(35) {
    transform: skewY(44deg) rotate(93deg) translate(55px, -1060px) scale(0.1);
    background: rgba(255, 119, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(36) {
    transform: skewY(13deg) rotate(75deg) translate(8px, -365px) scale(0.1);
    background: rgba(255, 0, 225, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(37) {
    transform: skewY(42deg) rotate(74deg) translate(87px, -1110px) scale(0.4);
    background: rgba(0, 255, 251, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(38) {
    transform: skewY(15deg) rotate(94deg) translate(100px, -480px) scale(0.1);
    background: rgba(0, 64, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(39) {
    transform: skewY(44deg) rotate(106deg) translate(67px, -470px) scale(0.1);
    background: rgba(234, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(40) {
    transform: skewY(21deg) rotate(190deg) translate(18px, -830px) scale(0.5);
    background: rgba(170, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(41) {
    transform: skewY(10deg) rotate(65deg) translate(77px, -465px) scale(0.3);
    background: rgba(0, 255, 238, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(42) {
    transform: skewY(40deg) rotate(263deg) translate(22px, -880px) scale(0.3);
    background: rgba(255, 0, 132, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(43) {
    transform: skewY(31deg) rotate(336deg) translate(94px, -2185px) scale(0.1);
    background: rgba(0, 106, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(44) {
    transform: skewY(3deg) rotate(79deg) translate(83px, -1105px) scale(0.3);
    background: rgba(72, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(45) {
    transform: skewY(49deg) rotate(143deg) translate(7px, -2495px) scale(0.2);
    background: rgba(255, 94, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(46) {
    transform: skewY(9deg) rotate(189deg) translate(91px, -1745px) scale(0.3);
    background: rgba(255, 0, 183, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(47) {
    transform: skewY(1deg) rotate(101deg) translate(46px, -505px) scale(0.1);
    background: rgba(255, 0, 247, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(48) {
    transform: skewY(10deg) rotate(14deg) translate(86px, -1095px) scale(0.5);
    background: rgba(255, 0, 225, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(49) {
    transform: skewY(18deg) rotate(346deg) translate(24px, -1225px) scale(0.3);
    background: rgba(34, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(50) {
    transform: skewY(36deg) rotate(209deg) translate(70px, -1920px) scale(0.1);
    background: rgba(0, 255, 221, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(51) {
    transform: skewY(30deg) rotate(96deg) translate(39px, -1475px) scale(0.5);
    background: rgba(0, 183, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(52) {
    transform: skewY(44deg) rotate(347deg) translate(91px, -415px) scale(0.3);
    background: rgba(0, 89, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(53) {
    transform: skewY(8deg) rotate(306deg) translate(15px, -1090px) scale(0.1);
    background: rgba(0, 255, 187, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(54) {
    transform: skewY(2deg) rotate(249deg) translate(8px, -2000px) scale(0.5);
    background: rgba(255, 0, 21, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(55) {
    transform: skewY(36deg) rotate(75deg) translate(48px, -2100px) scale(0.2);
    background: rgba(0, 255, 81, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(56) {
    transform: skewY(30deg) rotate(104deg) translate(99px, -2310px) scale(0.2);
    background: rgba(255, 0, 89, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(57) {
    transform: skewY(35deg) rotate(347deg) translate(99px, -1890px) scale(0.3);
    background: rgba(157, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(58) {
    transform: skewY(48deg) rotate(236deg) translate(94px, -1455px) scale(0.4);
    background: rgba(0, 85, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(59) {
    transform: skewY(37deg) rotate(133deg) translate(52px, -680px) scale(0.1);
    background: rgba(255, 64, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(60) {
    transform: skewY(49deg) rotate(354deg) translate(34px, -1170px) scale(0.1);
    background: rgba(85, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(61) {
    transform: skewY(9deg) rotate(246deg) translate(4px, -1775px) scale(0.1);
    background: rgba(255, 4, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(62) {
    transform: skewY(16deg) rotate(337deg) translate(17px, -1565px) scale(0.5);
    background: rgba(255, 221, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(63) {
    transform: skewY(5deg) rotate(2deg) translate(26px, -365px) scale(0.5);
    background: rgba(255, 0, 179, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(64) {
    transform: skewY(3deg) rotate(207deg) translate(55px, -475px) scale(0.4);
    background: rgba(255, 0, 38, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(65) {
    transform: skewY(26deg) rotate(201deg) translate(33px, -1240px) scale(0.1);
    background: rgba(0, 123, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(66) {
    transform: skewY(3deg) rotate(68deg) translate(67px, -445px) scale(0.3);
    background: rgba(255, 0, 72, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(67) {
    transform: skewY(22deg) rotate(228deg) translate(87px, -2295px) scale(0.1);
    background: rgba(76, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(68) {
    transform: skewY(15deg) rotate(107deg) translate(88px, -1045px) scale(0.2);
    background: rgba(0, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(69) {
    transform: skewY(22deg) rotate(343deg) translate(39px, -20px) scale(0.4);
    background: rgba(106, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(70) {
    transform: skewY(5deg) rotate(115deg) translate(90px, -1995px) scale(0.4);
    background: rgba(0, 140, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(71) {
    transform: skewY(32deg) rotate(188deg) translate(20px, -1730px) scale(0.5);
    background: rgba(13, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(72) {
    transform: skewY(43deg) rotate(119deg) translate(13px, -2065px) scale(0.3);
    background: rgba(242, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(73) {
    transform: skewY(40deg) rotate(181deg) translate(69px, -1575px) scale(0.3);
    background: rgba(255, 0, 34, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(74) {
    transform: skewY(16deg) rotate(295deg) translate(33px, -480px) scale(0.2);
    background: rgba(102, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(75) {
    transform: skewY(19deg) rotate(256deg) translate(8px, -280px) scale(0.2);
    background: rgba(196, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(76) {
    transform: skewY(48deg) rotate(145deg) translate(56px, -290px) scale(0.2);
    background: rgba(0, 247, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(77) {
    transform: skewY(50deg) rotate(358deg) translate(24px, -625px) scale(0.5);
    background: rgba(0, 255, 140, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(78) {
    transform: skewY(37deg) rotate(15deg) translate(14px, -1915px) scale(0.2);
    background: rgba(0, 102, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(79) {
    transform: skewY(46deg) rotate(335deg) translate(41px, -2455px) scale(0.3);
    background: rgba(255, 0, 242, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(80) {
    transform: skewY(26deg) rotate(26deg) translate(13px, -120px) scale(0.2);
    background: rgba(166, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(81) {
    transform: skewY(15deg) rotate(234deg) translate(75px, -1245px) scale(0.2);
    background: rgba(247, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(82) {
    transform: skewY(44deg) rotate(14deg) translate(74px, -1175px) scale(0.2);
    background: rgba(255, 0, 93, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(83) {
    transform: skewY(6deg) rotate(176deg) translate(27px, -370px) scale(0.4);
    background: rgba(0, 255, 162, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(84) {
    transform: skewY(40deg) rotate(300deg) translate(95px, -2185px) scale(0.4);
    background: rgba(183, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(85) {
    transform: skewY(24deg) rotate(30deg) translate(40px, -1080px) scale(0.5);
    background: rgba(255, 0, 106, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(86) {
    transform: skewY(36deg) rotate(131deg) translate(13px, -1880px) scale(0.1);
    background: rgba(255, 0, 102, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(87) {
    transform: skewY(24deg) rotate(297deg) translate(32px, -220px) scale(0.2);
    background: rgba(255, 204, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(88) {
    transform: skewY(6deg) rotate(248deg) translate(55px, -2185px) scale(0.5);
    background: rgba(0, 140, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(89) {
    transform: skewY(45deg) rotate(148deg) translate(15px, -1590px) scale(0.1);
    background: rgba(255, 0, 213, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(90) {
    transform: skewY(43deg) rotate(211deg) translate(21px, -385px) scale(0.2);
    background: rgba(255, 4, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(91) {
    transform: skewY(8deg) rotate(129deg) translate(71px, -1725px) scale(0.5);
    background: rgba(0, 123, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(92) {
    transform: skewY(24deg) rotate(68deg) translate(87px, -1500px) scale(0.1);
    background: rgba(89, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(93) {
    transform: skewY(40deg) rotate(294deg) translate(89px, -415px) scale(0.5);
    background: rgba(17, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(94) {
    transform: skewY(13deg) rotate(302deg) translate(19px, -1655px) scale(0.2);
    background: rgba(0, 187, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(95) {
    transform: skewY(47deg) rotate(322deg) translate(48px, -2470px) scale(0.1);
    background: rgba(77, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(96) {
    transform: skewY(42deg) rotate(287deg) translate(100px, -2290px) scale(0.2);
    background: rgba(255, 153, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(97) {
    transform: skewY(28deg) rotate(165deg) translate(79px, -1415px) scale(0.2);
    background: rgba(21, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(98) {
    transform: skewY(47deg) rotate(204deg) translate(33px, -295px) scale(0.4);
    background: rgba(0, 255, 208, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(99) {
    transform: skewY(45deg) rotate(236deg) translate(3px, -1600px) scale(0.4);
    background: rgba(123, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(100) {
    transform: skewY(42deg) rotate(336deg) translate(3px, -1190px) scale(0.3);
    background: rgba(255, 170, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(101) {
    transform: skewY(47deg) rotate(343deg) translate(38px, -25px) scale(0.5);
    background: rgba(106, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(102) {
    transform: skewY(4deg) rotate(58deg) translate(96px, -545px) scale(0.3);
    background: rgba(255, 0, 136, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(103) {
    transform: skewY(6deg) rotate(59deg) translate(59px, -1355px) scale(0.1);
    background: rgba(17, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(104) {
    transform: skewY(40deg) rotate(112deg) translate(54px, -455px) scale(0.2);
    background: rgba(51, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(105) {
    transform: skewY(38deg) rotate(290deg) translate(85px, -1935px) scale(0.5);
    background: rgba(255, 13, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(106) {
    transform: skewY(40deg) rotate(156deg) translate(15px, -1975px) scale(0.2);
    background: rgba(0, 64, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(107) {
    transform: skewY(14deg) rotate(290deg) translate(89px, -975px) scale(0.1);
    background: rgba(255, 0, 191, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(108) {
    transform: skewY(19deg) rotate(352deg) translate(63px, -90px) scale(0.5);
    background: rgba(0, 255, 217, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(109) {
    transform: skewY(33deg) rotate(140deg) translate(52px, -700px) scale(0.4);
    background: rgba(0, 255, 51, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(110) {
    transform: skewY(10deg) rotate(57deg) translate(53px, -1465px) scale(0.5);
    background: rgba(102, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(111) {
    transform: skewY(5deg) rotate(44deg) translate(72px, -415px) scale(0.4);
    background: rgba(247, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(112) {
    transform: skewY(16deg) rotate(159deg) translate(53px, -190px) scale(0.1);
    background: rgba(0, 51, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(113) {
    transform: skewY(4deg) rotate(22deg) translate(20px, -2370px) scale(0.2);
    background: rgba(255, 136, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(114) {
    transform: skewY(2deg) rotate(323deg) translate(73px, -1305px) scale(0.2);
    background: rgba(255, 0, 98, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(115) {
    transform: skewY(31deg) rotate(281deg) translate(65px, -2090px) scale(0.3);
    background: rgba(217, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(116) {
    transform: skewY(14deg) rotate(99deg) translate(39px, -965px) scale(0.1);
    background: rgba(255, 0, 47, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(117) {
    transform: skewY(28deg) rotate(206deg) translate(80px, -30px) scale(0.1);
    background: rgba(255, 153, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(118) {
    transform: skewY(34deg) rotate(96deg) translate(90px, -900px) scale(0.1);
    background: rgba(170, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(119) {
    transform: skewY(30deg) rotate(82deg) translate(74px, -1540px) scale(0.1);
    background: rgba(0, 255, 200, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(120) {
    transform: skewY(17deg) rotate(126deg) translate(33px, -995px) scale(0.3);
    background: rgba(255, 0, 89, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(121) {
    transform: skewY(41deg) rotate(138deg) translate(6px, -995px) scale(0.1);
    background: rgba(128, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(122) {
    transform: skewY(26deg) rotate(240deg) translate(72px, -1595px) scale(0.3);
    background: rgba(255, 0, 26, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(123) {
    transform: skewY(38deg) rotate(131deg) translate(65px, -910px) scale(0.3);
    background: rgba(0, 255, 81, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(124) {
    transform: skewY(4deg) rotate(25deg) translate(60px, -860px) scale(0.1);
    background: rgba(255, 64, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(125) {
    transform: skewY(10deg) rotate(327deg) translate(29px, -2430px) scale(0.4);
    background: rgba(0, 64, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(126) {
    transform: skewY(41deg) rotate(240deg) translate(89px, -85px) scale(0.5);
    background: rgba(0, 85, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(127) {
    transform: skewY(46deg) rotate(293deg) translate(55px, -1365px) scale(0.4);
    background: rgba(0, 255, 179, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(128) {
    transform: skewY(14deg) rotate(14deg) translate(54px, -1885px) scale(0.3);
    background: rgba(0, 255, 153, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(129) {
    transform: skewY(19deg) rotate(37deg) translate(72px, -55px) scale(0.2);
    background: rgba(0, 162, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(130) {
    transform: skewY(21deg) rotate(223deg) translate(3px, -1820px) scale(0.1);
    background: rgba(0, 255, 4, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(131) {
    transform: skewY(17deg) rotate(58deg) translate(100px, -120px) scale(0.4);
    background: rgba(0, 208, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(132) {
    transform: skewY(10deg) rotate(107deg) translate(30px, -1115px) scale(0.1);
    background: rgba(0, 60, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(133) {
    transform: skewY(32deg) rotate(248deg) translate(57px, -2350px) scale(0.3);
    background: rgba(208, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(134) {
    transform: skewY(28deg) rotate(5deg) translate(100px, -95px) scale(0.2);
    background: rgba(255, 0, 204, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(135) {
    transform: skewY(27deg) rotate(31deg) translate(52px, -1195px) scale(0.3);
    background: rgba(0, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(136) {
    transform: skewY(35deg) rotate(308deg) translate(85px, -2225px) scale(0.4);
    background: rgba(255, 0, 81, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(137) {
    transform: skewY(8deg) rotate(14deg) translate(8px, -1955px) scale(0.4);
    background: rgba(76, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(138) {
    transform: skewY(48deg) rotate(10deg) translate(85px, -595px) scale(0.2);
    background: rgba(221, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(139) {
    transform: skewY(30deg) rotate(12deg) translate(61px, -2005px) scale(0.1);
    background: rgba(0, 30, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(140) {
    transform: skewY(42deg) rotate(224deg) translate(55px, -400px) scale(0.2);
    background: rgba(0, 255, 196, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(141) {
    transform: skewY(40deg) rotate(141deg) translate(91px, -140px) scale(0.2);
    background: rgba(255, 0, 140, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(142) {
    transform: skewY(16deg) rotate(69deg) translate(16px, -720px) scale(0.2);
    background: rgba(255, 0, 111, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(143) {
    transform: skewY(18deg) rotate(44deg) translate(30px, -2175px) scale(0.5);
    background: rgba(255, 0, 213, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(144) {
    transform: skewY(7deg) rotate(313deg) translate(54px, -2060px) scale(0.5);
    background: rgba(0, 255, 191, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(145) {
    transform: skewY(43deg) rotate(61deg) translate(73px, -2365px) scale(0.3);
    background: rgba(98, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(146) {
    transform: skewY(49deg) rotate(260deg) translate(72px, -1630px) scale(0.5);
    background: rgba(89, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(147) {
    transform: skewY(46deg) rotate(70deg) translate(91px, -2425px) scale(0.4);
    background: rgba(85, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(148) {
    transform: skewY(42deg) rotate(19deg) translate(43px, -170px) scale(0.5);
    background: rgba(0, 13, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(149) {
    transform: skewY(9deg) rotate(236deg) translate(47px, -2500px) scale(0.4);
    background: rgba(115, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(150) {
    transform: skewY(26deg) rotate(111deg) translate(1px, -2100px) scale(0.1);
    background: rgba(0, 183, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(151) {
    transform: skewY(13deg) rotate(48deg) translate(19px, -2150px) scale(0.2);
    background: rgba(0, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(152) {
    transform: skewY(35deg) rotate(159deg) translate(68px, -1700px) scale(0.4);
    background: rgba(51, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(153) {
    transform: skewY(15deg) rotate(185deg) translate(86px, -400px) scale(0.5);
    background: rgba(0, 255, 157, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(154) {
    transform: skewY(12deg) rotate(42deg) translate(55px, -2470px) scale(0.5);
    background: rgba(255, 0, 34, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(155) {
    transform: skewY(44deg) rotate(94deg) translate(53px, -870px) scale(0.3);
    background: rgba(187, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(156) {
    transform: skewY(35deg) rotate(173deg) translate(18px, -1755px) scale(0.3);
    background: rgba(200, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(157) {
    transform: skewY(49deg) rotate(331deg) translate(12px, -2360px) scale(0.3);
    background: rgba(0, 255, 247, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(158) {
    transform: skewY(39deg) rotate(32deg) translate(60px, -1955px) scale(0.3);
    background: rgba(0, 255, 153, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(159) {
    transform: skewY(42deg) rotate(180deg) translate(81px, -1340px) scale(0.1);
    background: rgba(183, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(160) {
    transform: skewY(4deg) rotate(212deg) translate(23px, -1600px) scale(0.4);
    background: rgba(0, 230, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(161) {
    transform: skewY(8deg) rotate(101deg) translate(97px, -870px) scale(0.4);
    background: rgba(255, 0, 162, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(162) {
    transform: skewY(22deg) rotate(210deg) translate(13px, -2355px) scale(0.5);
    background: rgba(25, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(163) {
    transform: skewY(16deg) rotate(163deg) translate(38px, -910px) scale(0.1);
    background: rgba(217, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(164) {
    transform: skewY(19deg) rotate(168deg) translate(69px, -730px) scale(0.3);
    background: rgba(157, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(165) {
    transform: skewY(19deg) rotate(44deg) translate(95px, -295px) scale(0.1);
    background: rgba(38, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(166) {
    transform: skewY(15deg) rotate(154deg) translate(62px, -955px) scale(0.5);
    background: rgba(255, 0, 166, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(167) {
    transform: skewY(12deg) rotate(136deg) translate(41px, -1450px) scale(0.4);
    background: rgba(242, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(168) {
    transform: skewY(6deg) rotate(345deg) translate(26px, -1345px) scale(0.2);
    background: rgba(255, 0, 4, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(169) {
    transform: skewY(48deg) rotate(331deg) translate(82px, -1800px) scale(0.5);
    background: rgba(255, 132, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(170) {
    transform: skewY(24deg) rotate(191deg) translate(55px, -680px) scale(0.1);
    background: rgba(225, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(171) {
    transform: skewY(42deg) rotate(203deg) translate(30px, -55px) scale(0.4);
    background: rgba(255, 0, 191, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(172) {
    transform: skewY(30deg) rotate(327deg) translate(92px, -110px) scale(0.1);
    background: rgba(170, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(173) {
    transform: skewY(2deg) rotate(1deg) translate(24px, -860px) scale(0.5);
    background: rgba(0, 174, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(174) {
    transform: skewY(8deg) rotate(116deg) translate(35px, -2405px) scale(0.2);
    background: rgba(255, 0, 225, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(175) {
    transform: skewY(17deg) rotate(7deg) translate(68px, -1330px) scale(0.2);
    background: rgba(255, 166, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(176) {
    transform: skewY(21deg) rotate(2deg) translate(23px, -830px) scale(0.5);
    background: rgba(255, 0, 119, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(177) {
    transform: skewY(24deg) rotate(87deg) translate(97px, -2200px) scale(0.1);
    background: rgba(0, 255, 187, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(178) {
    transform: skewY(26deg) rotate(10deg) translate(23px, -2255px) scale(0.3);
    background: rgba(255, 247, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(179) {
    transform: skewY(31deg) rotate(355deg) translate(83px, -2010px) scale(0.1);
    background: rgba(255, 0, 230, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(180) {
    transform: skewY(8deg) rotate(29deg) translate(73px, -2465px) scale(0.4);
    background: rgba(255, 0, 34, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(181) {
    transform: skewY(7deg) rotate(332deg) translate(2px, -1840px) scale(0.3);
    background: rgba(123, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(182) {
    transform: skewY(35deg) rotate(236deg) translate(66px, -380px) scale(0.4);
    background: rgba(255, 115, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(183) {
    transform: skewY(17deg) rotate(263deg) translate(43px, -1090px) scale(0.2);
    background: rgba(0, 255, 72, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(184) {
    transform: skewY(44deg) rotate(138deg) translate(43px, -40px) scale(0.1);
    background: rgba(247, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 8px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(185) {
    transform: skewY(22deg) rotate(159deg) translate(51px, -540px) scale(0.2);
    background: rgba(0, 115, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(186) {
    transform: skewY(25deg) rotate(75deg) translate(19px, -2305px) scale(0.2);
    background: rgba(255, 0, 157, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 3px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(187) {
    transform: skewY(9deg) rotate(295deg) translate(69px, -470px) scale(0.4);
    background: rgba(166, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 2px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(188) {
    transform: skewY(11deg) rotate(51deg) translate(4px, -1095px) scale(0.3);
    background: rgba(123, 0, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(189) {
    transform: skewY(34deg) rotate(102deg) translate(46px, -160px) scale(0.2);
    background: rgba(0, 255, 170, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(190) {
    transform: skewY(23deg) rotate(53deg) translate(55px, -2165px) scale(0.5);
    background: rgba(0, 255, 51, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(191) {
    transform: skewY(41deg) rotate(74deg) translate(8px, -520px) scale(0.5);
    background: rgba(255, 102, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 10px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(192) {
    transform: skewY(10deg) rotate(288deg) translate(72px, -1155px) scale(0.3);
    background: rgba(0, 230, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 6px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(193) {
    transform: skewY(47deg) rotate(218deg) translate(88px, -1405px) scale(0.3);
    background: rgba(242, 255, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 4px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(194) {
    transform: skewY(26deg) rotate(223deg) translate(17px, -2110px) scale(0.5);
    background: rgba(255, 234, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.1s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(195) {
    transform: skewY(13deg) rotate(203deg) translate(10px, -430px) scale(0.2);
    background: rgba(0, 51, 255, 1);
    animation: 2s bang infinite;
    animation-delay: 0.2s;
    bottom: 7px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(196) {
    transform: skewY(20deg) rotate(247deg) translate(34px, -1630px) scale(0.5);
    background: rgba(0, 255, 26, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 5px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(197) {
    transform: skewY(9deg) rotate(106deg) translate(59px, -735px) scale(0.5);
    background: rgba(255, 166, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 1px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(198) {
    transform: skewY(7deg) rotate(324deg) translate(68px, -375px) scale(0.5);
    background: rgba(255, 94, 0, 1);
    animation: 2s bang infinite;
    animation-delay: 0.05s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(199) {
    transform: skewY(33deg) rotate(213deg) translate(64px, -805px) scale(0.4);
    background: rgba(255, 0, 60, 1);
    animation: 2s bang infinite;
    animation-delay: 0.15s;
    bottom: 9px;
    opacity: 0.3;
}
.confetti.animated p:nth-of-type(200) {
    transform: skewY(8deg) rotate(228deg) translate(88px, -2295px) scale(0.1);
    background: rgba(255, 0, 26, 1);
    animation: 2s bang infinite;
    animation-delay: 0.25s;
    bottom: 4px;
    opacity: 0.3;
}
@keyframes bang {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
   }
}

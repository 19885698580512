.popUpContent {
  color: #ffd81f;
  background: red;
  font-size: 20px;
  border-radius: 0.5rem;
  font-family: "Abril Fatface", cursive;
  animation: enlarge 1.5s 1;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(3600deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes float {
  0% {
    top: 70px;
  }
  50% {
    top: 90px;
  }
  100% {
    top: 70px;
  }
}

@keyframes enlarge {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    position: absolute;
    top: 40%;
    left: 40%;
    transform: scale(0);
  }
  50% {
    /* transform: scale(1.1); */
    position: absolute;
    top: 35%;
    left: 25%;
    opacity: 0.4;
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.arrow {
  transform: rotate(90deg);
}

.animate-float {
  animation: float 1s infinite;
}

.animate-enlarge {
  animation: enlarge 1s infinite;
}

@keyframes pendulum {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.pendulum {
  animation: pendulum 2s ease-in-out infinite;
  transform-origin: top center;
}

.blur-effect {
  filter: blur(20px);
  transition: filter 0.5s ease;
}

.gradient-border {
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  background: rgba(71, 92, 58, 1);
  backdrop-filter: blur(8px) !important;
  /* box-shadow: inset 0 0 25px 5px hsla(0, 0%, 100%, 0.6); */
  /* padding: 20px !important; */
  /* z-index: 1; */
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit; /* Inherit border-radius from parent */
  padding: 1px; /* Create space for the gradient border */
  background: linear-gradient(
    114.53deg,
    rgba(71, 92, 58, 0.6) 0%,
    rgba(71, 92, 58, 0.6) 10%,
    rgba(71, 92, 58, 0) 30%,
    /* Invisible middle section */ rgba(271, 92, 58, 0) 70%,
    /* Continue invisible */ rgba(71, 92, 58, 0.6) 90%,
    rgba(71, 92, 58, 0.6) 100%
  );
  -webkit-mask: linear-gradient(#475C3A 0 0) content-box, linear-gradient(#475C3A 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* z-index: 0;  */
  backdrop-filter: blur(8px) !important; /* Existing backdrop filter */
}

.bUIUWk {
  display: none !important;
}
.psOdd {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.eybQJB{
  background-color: #F1FFE6 !important;
}
.pRAAa{
  background-color: #F1FFE6 !important;
}
.hmRSRo{
  background-color: #475C3A !important;
}
.psOdd{
  max-width: 100% !important;
}
.etIzRP{
  background-color: #F9B636 !important;
}
.kfkiND{
  color: #000000 !important;
}

.top-leftLottie {
  position: absolute;
  top: -90px;
  left: -0px;
  z-index: 0;
}
.top-rightLottie {
  position: absolute;
  bottom: 100px;
  right: -20px;
  overflow: hidden !important;
}
@media only screen and (max-width: 768px) {
  .top-rightLottie {
    position: absolute;
    bottom: 100px;
    right: -28px;
    overflow: hidden !important;
  }
  .top-leftLottie {
    position: absolute;
    top: -50px;
    left: 15px;
    z-index: 0;
  }
}
.swipArrow {
  animation: changeWidth;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
@keyframes changeWidth {
  0% {
    position: relative;
    left: 0;
  }
  100% {
    position: relative;
    left: 14px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  --moz-appearance: textfield;
}

/* 3D button */
.btn-3d {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #4d4dff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.btn-3d:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: inherit;
  z-index: -1;
  transition: transform 0.3s, box-shadow 0.3s;
}

.btn-3d:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.btn-3d:hover:before {
  transform: translateY(3px);
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3);
}
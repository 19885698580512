@keyframes zoomIn {
 
  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(3);
  }
}
.giftBoxRoate {
  transform: rotate(0deg) translate(0.5rem);
}
@media screen and (max-width: 480px) {
  .giftBoxRoate {
    transform: rotate(0deg) translate(0.8rem);
  }
}
